import { BrowserRouter, Routes, Route } from "react-router-dom";
import Paths from "./Paths";
import AuthRoute from "./Guards/AuthRoute";
import PublicRoute from "./Guards/PublicRoute";
import CustomSuspense from "components/UI/RenderElements/CustomSuspense";
import React, { lazy, useMemo, Suspense } from "react";

// Lazy load the dashboard layout
const DashboardLayout = lazy(() => import("components/layout/Dashboard"));

// Preload critical routes when browser is idle to speed up subsequent navigations
const preloadRoutes = () => {
  if (typeof window !== "undefined" && "requestIdleCallback" in window) {
    window.requestIdleCallback(() => {
      // Preload login route which is likely to be accessed first
      const loginComponent = Paths.find(
        (route) => route.path === "/login"
      )?.component;
      if (
        loginComponent &&
        loginComponent.type &&
        loginComponent.type.preload
      ) {
        loginComponent.type.preload();
      }
    });
  }
};

const Router = () => {
  const pageData = useMemo(() => Paths, []);

  // Trigger preloading of critical routes
  useMemo(() => {
    preloadRoutes();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {pageData.map((route) => (
          <Route
            key={route.path}
            element={
              route.layout === "dashboard" ? (
                <Suspense
                  fallback={
                    <div
                      style={{
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading...
                    </div>
                  }
                >
                  <DashboardLayout />
                </Suspense>
              ) : null
            }
          >
            <Route
              path={route.path}
              key={route.path}
              element={
                route.public ? (
                  <PublicRoute>
                    <CustomSuspense>{route.component}</CustomSuspense>
                  </PublicRoute>
                ) : (
                  <AuthRoute>
                    <CustomSuspense>{route.component}</CustomSuspense>
                  </AuthRoute>
                )
              }
            ></Route>

            {route.children &&
              route.children.map((child) => (
                <Route
                  path={child.path}
                  key={child.path}
                  element={<CustomSuspense>{child.component}</CustomSuspense>}
                />
              ))}
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
