import axios from "axios";
import { getLocalStorage } from "utils/helpers";

// Simple cache for API responses
const responseCache = new Map();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Map to store cancel tokens by request ID
const cancelTokenMap = new Map();

const axiosClient = (url, interceptors = true) => {
  const instance = axios.create({
    baseURL: url,
    timeout: 10000, // Add a reasonable timeout
  });

  if (interceptors) {
    instance.interceptors.response.use(
      (res) => {
        if (!res) {
          throw new Error("Response is not found");
        }
        return res;
      },
      async (err) => {
        console.log(`[axios][interceptors][response][failure] ${err}`);
        throw err;
      }
    );
  }

  function getCacheKey(config) {
    return `${config.method}:${config.url}:${JSON.stringify(config.params)}`;
  }

  function isCacheable(config) {
    // Only cache GET requests
    return config.method === "get";
  }

  function getFromCache(cacheKey) {
    const cached = responseCache.get(cacheKey);
    if (!cached) return null;

    const now = Date.now();
    if (now - cached.timestamp > CACHE_DURATION) {
      responseCache.delete(cacheKey);
      return null;
    }

    return cached.data;
  }

  function saveToCache(cacheKey, data) {
    responseCache.set(cacheKey, {
      data,
      timestamp: Date.now(),
    });
  }

  function makeRequest(type, path, queryParams = {}, body = {}, options = {}) {
    // Generate a unique request ID
    const requestId =
      options.requestId || `${type}:${path}:${JSON.stringify(queryParams)}`;

    // Cancel previous request with the same ID if it exists
    if (cancelTokenMap.has(requestId)) {
      cancelTokenMap
        .get(requestId)
        .cancel(`Request ${requestId} canceled due to duplicate`);
      cancelTokenMap.delete(requestId);
    }

    // Create a new cancel token for this request
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenMap.set(requestId, cancelTokenSource);

    const requestConfig = {
      method: type,
      url: path,
      params: queryParams,
      data: body,
      headers: {
        Authorization: "public",
        ...options.headers,
      },
      cancelToken: cancelTokenSource.token,
      ...options,
    };

    // Check cache for GET requests
    if (isCacheable(requestConfig)) {
      const cacheKey = getCacheKey(requestConfig);
      const cachedResponse = getFromCache(cacheKey);

      if (cachedResponse) {
        return Promise.resolve(cachedResponse);
      }
    }

    return new Promise((resolve, reject) => {
      instance(requestConfig)
        .then((res) => {
          // Remove the cancel token from the map once request is complete
          cancelTokenMap.delete(requestId);

          // Cache the response if it's a GET request
          if (isCacheable(requestConfig)) {
            const cacheKey = getCacheKey(requestConfig);
            saveToCache(cacheKey, res.data);
          }
          resolve(res.data);
        })
        .catch((err) => {
          // Remove the cancel token from the map on error
          cancelTokenMap.delete(requestId);

          if (axios.isCancel(err)) {
            console.log(`Request ${requestId} was canceled:`, err.message);
            return;
          }
          reject(err.response || err);
        });
    });
  }

  async function makeRequestAuth(
    type,
    path,
    queryParams = {},
    body = {},
    options = {}
  ) {
    const token = getLocalStorage("token");
    if (!token) return;

    return makeRequest(type, path, queryParams, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...options.headers,
      },
      ...options,
    });
  }

  return {
    instance,
    makeRequest,
    makeRequestAuth,
  };
};

export default axiosClient;
