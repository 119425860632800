import { CircularProgress, Box } from "@mui/material";
import { React, Suspense } from "react";

const FallbackCircularProgress = () => (
  <Box
    height="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress size={40} />
  </Box>
);

const CustomSuspense = ({ fallback, children }) => {
  return (
    <Suspense fallback={fallback || <FallbackCircularProgress />}>
      {children}
    </Suspense>
  );
};

export default CustomSuspense;
