import "./index.css";
import AppRouter from "routes";
import { logout } from "store/auth";
import { UserService } from "services";
import { updateUser } from "store/user";
import { useDispatch } from "react-redux";
import { useEffect, useState, Suspense } from "react";
import { getLocalStorage } from "utils/helpers";
import ThemeCustomization from "themes";
import { useSelector } from "react-redux";

import LoadingPage from "components/UI/LoadingPage";

const userService = new UserService();

const App = () => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // Immediately render the app shell
    let isMounted = true;

    async function fetchData() {
      try {
        const storedToken = getLocalStorage("token");

        if (!storedToken) {
          if (isMounted) setLoading(false);
          return;
        }

        // Perform token verification asynchronously
        const res = await userService.verifyToken(storedToken);

        if (!res?.data.user?.id) {
          dispatch(logout());
          if (isMounted) setLoading(false);
          return;
        }

        // Fetch user data
        const user_data = await userService.getUserById(res?.data.user?.id);

        if (isMounted) {
          const { user } = user_data.data;
          dispatch(
            updateUser({
              user,
              token: storedToken,
            })
          );
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          dispatch(logout());
          setLoading(false);
        }
      }
    }

    fetchData();

    // Cleanup function to prevent state updates if component unmounts
    return () => {
      isMounted = false;
    };
  }, [dispatch, token]);

  return (
    <LoadingPage loading={loading}>
      <ThemeCustomization>
        <Suspense fallback={<LoadingPage loading={true} />}>
          <AppRouter />
        </Suspense>
      </ThemeCustomization>
    </LoadingPage>
  );
};

export default App;
